<template>
 <div>
    <b-card>
    <b-tabs>
      <b-tab active title="English">
        <b-card-text>
          <validation-observer ref="thingsToDoForm">
            <b-form>
              <b-row>
                <b-col md="6" class="align-self-center">
                  <b-form-group class="mb-0">
                    <validation-provider
                      #default="{ errors }"
                      name="Title"
                      rules="required"
                    >
                      <b-form-input
                        v-model="todo.title"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Title"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>

                    <form-group label="URL">
                      <b-form-input
                        class="mt-2"
                        v-model="todo.buttonURL"
                        placeholder="URL"
                      />
                    </form-group>

                    <b-form-input
                      class="mt-2"
                      v-model="todo.buttonTitle"
                      placeholder="Button Text"
                    />

                    <b-form-textarea
                      class="mt-2"
                      v-model="todo.description"
                      id="textarea-rows"
                      placeholder="Description"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="2" class="align-self-center">
                  <ComponentMediaSelector
                  :params="{lang: null}"
                    :isMultiple="false"
                    @mediaSelected="handleMediaSelect"
                  />
                </b-col>
                <b-col v-if="todo.thumbnailImg" md="3">
                  <b-img v-bind="mainProps" rounded :src="todo.thumbnailImg" />
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card-text>
      </b-tab>
      <b-tab title="Hindi">
        <b-card-text>
          <b-form>
            <b-row>
              <b-col md="6" class="align-self-center">
                <b-form-group class="mb-0">
                  <b-form-input
                    v-model="todo.translations.hi.title"
                    placeholder="Title (Hindi)"
                  />
<b-form-input
                        class="mt-2"
                        v-model="todo.translations.hi.buttonURL"
                        placeholder="URL"
                      />
                  <b-form-input
                    class="mt-2"
                    v-model="todo.translations.hi.buttonTitle"
                    placeholder="Button Text (Hindi)"
                  />
                      
                  <b-form-textarea
                    class="mt-2"
                    v-model="todo.translations.hi.description"
                    id="textarea-rows"
                    placeholder="Description (Hindi)"
                  />

                </b-form-group>

              </b-col>
              <b-col md="2" class="align-self-center">
                  <ComponentMediaSelector
                  :params="{lang: 'hi'}"
                    :isMultiple="false"
                    @mediaSelected="handleMediaSelect"
                  />
                </b-col>
                <b-col v-if="todo.translations.hi.thumbnailImg" md="3">
                  <b-img v-bind="mainProps" rounded :src="todo.translations.hi.thumbnailImg" />
                </b-col>
            </b-row>
          </b-form>
        </b-card-text>
      </b-tab>
      <b-tab title="Marathi">
        <b-card-text>
          <b-form>
            <b-row>
              <b-col md="6" class="align-self-center">
                <b-form-group class="mb-0">
                  <b-form-input
                    v-model="todo.translations.mr.title"
                    placeholder="Title (Marathi)"
                  />
<b-form-input
                        class="mt-2"
                        v-model="todo.translations.mr.buttonURL"
                        placeholder="URL"
                      />
                  <b-form-input
                    class="mt-2"
                    v-model="todo.translations.mr.buttonTitle"
                    placeholder="Button Text (Marathi)"
                  />

                  <b-form-textarea
                    class="mt-2"
                    v-model="todo.translations.mr.description"
                    id="textarea-rows"
                    placeholder="Description (Marathi)"
                  />
                </b-form-group>
              </b-col>
              <b-col md="2" class="align-self-center">
                  <ComponentMediaSelector
                  :params="{lang: 'mr'}"
                    :isMultiple="false"
                    @mediaSelected="handleMediaSelect"
                  />
                </b-col>
                <b-col v-if="todo.translations.mr.thumbnailImg" md="3">
                  <b-img v-bind="mainProps" rounded :src="todo.translations.hi.thumbnailImg" />
                </b-col>
            </b-row>
          </b-form>
        </b-card-text>
      </b-tab>
    </b-tabs>
  </b-card>
  <b-card>
      <b-row>
        <b-col>
          <b-button @click="createThingsToDo" size="lg" block variant="primary"
            >Submit</b-button
          >
        </b-col>
      </b-row>
  </b-card>
 </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { required } from "@validations";
import {
  BTabs,
  BTab,
  BCardText,
  BCard,
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BImg,
  BFormTextarea,
} from "bootstrap-vue";
import ComponentMediaSelector from "@/views/Gallery/ComponentMediaSelector";
import axios from "axios";
import { getUserToken } from "@/auth/utils";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BTabs,
    BCardText,
    BTab,
    BCard,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    ComponentMediaSelector,
    BImg,
    BFormTextarea,
    vSelect,
  },
  data() {
    return {
      required,
      mainProps: {
        width: 175,
        height: 175,
      },
      todo: {
        title: "",
        description: "",
        thumbnailImg: "",
        buttonTitle: "",
        buttonURL: "",
        translations: {
          hi: {
            title: "",
            description: "",
            thumbnailImg: "",
            buttonTitle: "",
            buttonURL: ""
          },
          mr: {
            title: "",
            description: "",
            thumbnailImg: "",
            buttonTitle: "",
            buttonURL: ""
          },
        },
      },
    };
  },
  methods: {
    makeToast(variant, title, content) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
    handleMediaSelect(mediaObject) {
      console.log("Media Recieved", mediaObject);
      if (mediaObject.status) {
        if(mediaObject.params.lang) {
        this.todo.translations[mediaObject.params.lang].thumbnailImg = mediaObject.media.src;
        } else {
        this.todo.thumbnailImg = mediaObject.media.src;
        }
      }
    },
    createThingsToDo() {
      this.$refs.thingsToDoForm.validate().then((success) => {
        if (success) {
          if (!this.todo.thumbnailImg) {
            this.makeToast(
              "danger",
              "Error",
              "Select an Image for the To Do item!"
            );
            return;
          }

          axios
            .post(
              `${process.env.VUE_APP_SERVER_URL}/hotel/thingsToDo/add`,
              this.todo,
              {
                headers: {
                  Authorization: `Bearer ${getUserToken()}`,
                },
              }
            )
            .then((response) => {
              this.makeToast(
                "success",
                "Success",
                `${response.data.title} has been created successfully!`
              );
            })
            .catch((error) => {
              console.log(error.response);
              this.makeToast(
                "danger",
                "Error",
                "Error while creating To Do item!"
              );
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>